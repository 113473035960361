<template>
  <app-layout>
    <div class="w-full px-5">
      <div class="flex flex-col bg-white bg-opacity-40">
        <div class="w-10/12 mx-auto">
          <button type="button"
            class="bg-green-1000 text-white px-6 py-3 mt-12 absolute rounded-md border border-gray-400 focus:outline-none"
            @click="goBack">
            Voltar
          </button>
        </div>
        <div class="w-full lg:w-10/12 flex justify-center mx-auto">
          <form-wizard class="w-full lg:w-8/12" @on-complete="onComplete" :start-index="0" color="#4aa263" title=""
            subtitle="" ref="form" next-button-text="Próximo" back-button-text="Anterior"
            finish-button-text="Finalizar">
            <tab-content title="Identificação da empresa" icon="ti-user">
              <div class="w-full border border-green-1001 bg-white rounded-lg py-3 px-5 mx-auto mt-5">
                <keepAlive>
                  <div class="flex w-full mb-5">
                    <div class="flex mb-5 w-full flex-col">
                      <label class="flex items-center mb-2" for="syndicates">
                        Sindicatos
                      </label>
                      <multiselect id="syndicates" v-model="$v.syndicates.selected.$model" tag-placeholder="Adicionar"
                        placeholder="Procure aqui..." label="name" track-by="name" :options="syndicates.filteredOptions"
                        :taggable="true" select-label="Selecionar" deselect-label="Remover" selected-label="Selecionado"
                        @tag="addSyndicate" :internal-search="false" @search-change="syndicatesSearchChange"
                        :multiple="true" :max="1" :disabled="isDisabled">
                        <span slot="noOptions">Comece a pesquisar para que os sindicatos apareçam
                          aqui.</span>
                        <template v-slot:maxElements>Só é permitido selecionar um sindicato, remova a
                          opção selecionada para escolher um novo
                          sindicato</template>
                      </multiselect>

                      <div v-if="$v.syndicates.selected.$error">
                        <div class="error" v-if="!$v.syndicates.selected.required">
                          Campo obrigatório.
                        </div>
                      </div>
                    </div>
                  </div>
                </keepAlive>
                <div class="flex w-full mb-5 flex-col md:flex-row">
                  <div class="flex w-full md:w-6/12 flex-col mr-5">
                    <label class="mb-2" for="type">Tipo de Associado</label>
                    <multiselect id="type" v-model="$v.associate.types.selected.$model" track-by="label" label="label"
                      select-label="Selecionar" selected-label="Selecionado" deselect-label="Remover"
                      :searchable="false" placeholder="Selecione um tipo" :options="associate.types.options">
                      <template slot="singleLabel" slot-scope="{ option }">
                        {{ option.label }}
                      </template>
                      <span slot="noOptions">Nenhum registro encontrado.</span>
                    </multiselect>
                    <div v-if="$v.associate.types.selected.$error">
                      <div class="error" v-if="!$v.associate.types.selected.required">
                        Campo obrigatório.
                      </div>
                    </div>
                  </div>

                  <div class="flex w-full md:w-6/12 flex-col">
                    <label class="mb-2" for="entity">Empregador (Pessoa Fisica ou Pessoa Juridida)</label>
                    <multiselect id="entity" v-model="$v.associate.entities.selected.$model" deselect-label="Remover"
                      track-by="label" label="label" select-label="Selecionar" selected-label="Selecionado"
                      :searchable="false" placeholder="Selecione uma entidade" :options="associate.entities.options"
                      @select="toggleDocument" disabled>
                      <template slot="singleLabel" slot-scope="{ option }">
                        {{ option.label }}
                      </template>
                      <span slot="noOptions">Nenhum registro encontrado.</span>
                    </multiselect>
                    <div v-if="$v.associate.entities.selected.$error">
                      <div class="error" v-if="!$v.associate.entities.selected.required">
                        Campo obrigatório.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex w-full mb-5 flex-col md:flex-row">
                  <div class="flex w-full md:w-6/12 flex-col mr-5">
                    <label class="mb-2" for="name">Nome da Empresa ou Propriedade</label>
                    <input v-model="$v.associate.name.$model"
                      class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="name" type="text" placeholder="Digite o nome do associado" autocomplete="off" />
                    <div v-if="$v.associate.name.$error">
                      <div class="error" v-if="!$v.associate.name.required">
                        Campo obrigatório.
                      </div>
                      <div class="error" v-else-if="!$v.associate.name.maxLength">
                        O nome do produtor deve ter no máximo
                        {{ $v.associate.name.$params.maxLength.max }}
                        caracteres.
                      </div>
                    </div>
                  </div>

                  <div class="flex w-full md:w-6/12 flex-col">
                    <label class="mb-2">Documento da Empresa ou Propriedade Rural (CPF ou
                      CNPJ)</label>
                    <input v-model="$v.associate.document.$model"
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                      type="text" placeholder="Documento (CPF ou CNPJ)" v-mask="documentMask" autocomplete="off"
                      disabled />
                    <div v-if="$v.associate.document.$error">
                      <div class="error" v-if="!$v.associate.document.required">
                        Campo obrigatório.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex w-full mb-5 flex-col md:flex-row">
                  <div class="flex w-full md:w-6/12 flex-col mr-5">
                    <label class="mb-2" for="telephone">Telefone fixo</label>
                    <input v-model="associate.telephone"
                      class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="telephone" type="text" placeholder="Telefone" v-mask="'(##) ####-####'" autocomplete="off" />
                  </div>

                  <div class="flex w-full md:w-6/12 flex-col">
                    <label class="mb-2" for="cellphone">Telefone celular</label>
                    <input v-model="associate.cellphone"
                      class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="cellphone" type="text" placeholder="Celular" v-mask="['(##) ####-####', '(##) #####-####']"
                      autocomplete="off" />
                  </div>
                </div>
              </div>
            </tab-content>
            <tab-content title="Endereço da empresa" icon="ti-location-pin">
              <div class="w-12/12 border border-green-1001 bg-white  rounded-lg py-3 px-5 mx-auto mt-5">
                <div class="flex w-full mb-5 flex-col md:flex-row">
                  <div class="flex w-full md:w-3/12 flex-col mr-5">
                    <label class="mb-2" for="cep">CEP</label>
                    <input id="cep" type="text" v-model="address.cep"
                      class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                      placeholder="CEP" v-mask="'#####-###'" />
                    <div v-if="$v.address.cep.$error">
                      <div class="error" v-if="!$v.address.cep.required">
                        Campo obrigatório.
                      </div>
                    </div>
                  </div>

                  <div class="flex w-full md:w-7/12 flex-col mr-5">
                    <label class="mb-2" for="address">Endereço</label>
                    <input id="address" type="text" v-model="address.address"
                      class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                      placeholder="Endereço" />
                    <div v-if="$v.address.address.$error">
                      <div class="error" v-if="!$v.address.address.required">
                        Campo obrigatório.
                      </div>
                    </div>
                  </div>

                  <div class="flex w-full md:w-2/12 flex-col mr-5">
                    <label class="mb-2" for="number">Número</label>
                    <input id="number" type="text" v-model="address.number"
                      class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                      placeholder="Número" />
                    <div v-if="$v.address.number.$error">
                      <div class="error" v-if="!$v.address.number.required">
                        Campo obrigatório.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex w-full mb-5 flex-col md:flex-row">
                  <div class="flex w-full md:w-6/12 flex-col mr-5">
                    <label class="mb-2" for="complement">Complemento</label>
                    <input id="complement" type="text" v-model="address.complement"
                      class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                      placeholder="Complemento" />
                  </div>

                  <div class="flex w-full md:w-6/12 flex-col mr-5">
                    <label class="mb-2" for="neighborhood">Bairro</label>
                    <input id="neighborhood" type="text" v-model="address.neighborhood"
                      class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                      placeholder="Bairro" />
                    <div v-if="$v.address.neighborhood.$error">
                      <div class="error" v-if="!$v.address.neighborhood.required">
                        Campo obrigatório.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex w-full mb-5 flex-col md:flex-row">
                  <div class="flex w-full md:w-6/12 flex-col mr-5">
                    <label class="mb-2" for="state">Estado</label>
                    <multiselect id="state" v-model="address.states.selected" deselect-label="Remover" track-by="name"
                      label="name" select-label="Selecionar" selected-label="Selecionado"
                      placeholder="Selecione um estado" :options="address.states.options" @select="fetchCities">
                      <template slot="singleLabel" slot-scope="{ option }">
                        {{ option.name }}
                      </template>
                      <span slot="noOptions">Nenhum registro encontrado.</span>
                    </multiselect>
                    <div v-if="$v.address.states.selected.$error">
                      <div class="error" v-if="!$v.address.states.selected.required">
                        Campo obrigatório.
                      </div>
                    </div>
                  </div>

                  <div class="flex w-full md:w-6/12 flex-col mr-5">
                    <label class="mb-2" for="city">Cidade</label>
                    <multiselect id="city" v-model="$v.address.cities.selected.$model"
                      deselect-label="Clique para remover" track-by="name" label="name" :taggable="true"
                      :internal-search="false" select-label="Selecionar" selected-label="Selecionado"
                      placeholder="Selecione uma cidade" :options="address.cities.filteredOptions"
                      @search-change="citiesSearchChange">
                      <span slot="noOptions">Nenhum registro encontrado.</span>
                    </multiselect>
                    <div v-if="$v.address.cities.selected.$error">
                      <div class="error" v-if="!$v.address.cities.selected.required">
                        Campo obrigatório.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </tab-content>
            <tab-content title="Acessos para Funcionários" icon="ti-user">
              <div class="w-12/12 border border-green-600 bg-white rounded-lg py-3 px-5 mx-auto mt-5">
                <div class="flex w-full mb-5 flex-col md:flex-row">
                  <div class="flex w-full md:w-6/12 flex-col mr-5">
                    <label class="mb-2" for="profileName">Nome</label>
                    <input v-model="$v.access.name.$model"
                      class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="profileName" type="text" placeholder="Nome" autocomplete="off" />
                    <div v-if="$v.access.name.$error">
                      <div class="error" v-if="!$v.access.name.required && profiles.length < 1">
                        Campo obrigatório.
                      </div>
                      <div class="error" v-else-if="!$v.access.name.maxLength">
                        O nome do usuário deve ter no máximo
                        {{ $v.access.name.$params.maxLength.max }} caracteres.
                      </div>
                    </div>
                  </div>

                  <div class="flex w-full md:w-6/12 flex-col mr-5">
                    <label class="mb-2" for="email">E-mail de acesso</label>
                    <input v-model="$v.access.email.$model"
                      class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="email" type="email" placeholder="E-mail de acesso" autocomplete="off" />
                      <span class="text-xs	text-red-600	">*Insira um e-mail valido, será necessario confirmá-lo para acessar a plataforma.</span>
                    <div v-if="$v.access.email.$error">
                      <div class="error" v-if="!$v.access.email.required && profiles.length < 1">
                        Campo obrigatório.
                      </div>
                      <div class="error" v-if="!$v.access.email.email">
                        E-mail inválido.
                      </div>
                      <div class="error" v-else-if="!$v.access.email.maxLength">
                        O e-mail de acesso deve ter no máximo
                        {{ $v.access.email.$params.maxLength.max }} caracteres.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex w-full mb-5 flex-col md:flex-row">
                  <div class="flex w-full md:w-6/12 flex-col mr-5">
                    <label class="mb-2" for="password">Senha</label>
                    <input v-model="$v.access.password.$model"
                      class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="password" type="password" placeholder="Senha" />
                    <div v-if="$v.access.password.$error">
                      <div class="error" v-if="
                        !$v.access.password.required && profiles.length < 1
                      ">
                        Campo obrigatório.
                      </div>
                      <div class="error" v-else-if="!$v.access.password.minLength">
                        A senha deve ter no mínimo
                        {{ $v.access.password.$params.minLength.min }}
                        caracteres.
                      </div>
                    </div>
                  </div>

                  <div class="flex w-full md:w-6/12 flex-col mr-5">
                    <label class="mb-2" for="password_confirmation">Confirmar Senha</label>
                    <input v-model="$v.access.password_confirmation.$model"
                      class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="password_confirmation" type="password" placeholder="Confirmar Senha" />
                    <div v-if="$v.access.password_confirmation.$error">
                      <div class="error" v-if="
                        !$v.access.password_confirmation.required &&
                        profiles.length < 1
                      ">
                        Campo obrigatório.
                      </div>
                      <div class="error" v-else-if="!$v.access.password_confirmation.minLength">
                        A senha deve ter no mínimo
                        {{
                          $v.access.password_confirmation.$params.minLength.min
                        }}
                        caracteres.
                      </div>
                      <div class="error" v-else-if="
                        !$v.access.password_confirmation.sameAsPassword
                      ">
                        As senhas devem ser idênticas.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col">
                  <div class="w-12/12 flex justify-end pr-5">
                    <button
                      class="bg-green-1002 text-white px-6 py-1 rounded rounded-full border border-gray-400 focus:outline-none"
                      @click="addProfile()">
                      Adicionar
                    </button>
                  </div>
                </div>
                <v-client-table ref="table" :data="profiles" :columns="columns" :options="options">
                  <div class="flex justify-center" slot="action" slot-scope="{ index }">
                    <button @click="removeProfile(index)"
                      class="flex p-1 border-2 border-green-1002 w-max rounded-lg mr-4" v-tooltip="{
                        content: 'Excluir Acesso',
                        placement: 'left'
                      }">
                      <i class="ri-delete-bin-fill text-green-1002"></i>
                    </button>
                  </div>
                </v-client-table>
                <p class="text-sm text-red-500">
                  <i class="ri-asterisk"></i> Você pode cadastrar até 3 acessos
                  provisórios.
                </p>
              </div>
            </tab-content>
          </form-wizard>
        </div>
      </div>
    </div>
    <modal-layout>
      <template v-slot:header>
        <div class="flex items-center flex-col p-3">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="35" height="35">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z"
              fill="rgba(58,230,4,1)" />
          </svg>
          <h1>Tudo certo...</h1>
        </div>
      </template>
      <template v-slot:body>
        <div class="p-5">
          <p class="mb-2">
            Para acessar sua conta é necessario confirmar o seu e-mail, enviamos um link de ativação para os e-mails
            cadastrados.
          </p>
          <p class="mb-2">
            Lembrando que seus acessos são provisórios e depois de 60 dias suas
            ações dentro do sistemas serão limitadas.
          </p>
          <p class="mb-2">
            Entre em contato com o seu sindicato para liberação do seu acesso
            definitivo.
          </p>
          <p class="mb-2">Telefone : {{ syndicates.telephone }}</p>
        </div>
      </template>
      <template v-slot:footer>
        <button @click="closeModal"
          class="bg-green-1000 mb-5 py-1 px-5 text-white text-center cursor-pointer font-bold border rounded-full">
          OK
        </button>
      </template>
    </modal-layout>
  </app-layout>
</template>

<script>
import AppLayout from '../../layouts/AppLayout';
import { mask } from 'vue-the-mask';
import {
  required,
  minLength,
  maxLength,
  sameAs,
  email
} from 'vuelidate/lib/validators';
import axios from '@/utils/axios';
import moment from 'moment';
import ModalLayout from '../../components/Modal';

export default {
  name: 'CreateAssociate',

  title() {
    return `${process.env.VUE_APP_NAME} | Criar Associado`;
  },

  components: {
    AppLayout,
    ModalLayout
  },

  directives: { mask },

  data() {
    return {
      isDisabled: false,
      isCpf: false,
      redirect: false,
      profiles: [],
      associate: {
        name: '',
        document: '',
        telephone: '',
        cellphone: '',
        types: {
          options: [
            { label: 'Produtor', value: 1 },
            { label: 'Parceiro', value: 2 },
            { label: 'Empresa do Agronegócio', value: 3 }
          ],
          selected: null
        },
        entities: {
          options: [
            { label: 'Pessoa Física', value: 1 },
            { label: 'Pessoa Jurídica', value: 2 }
          ],
          selected: null
        },
        status: 2,
        grant_access_at: '',
        revoke_access_at: ''
      },

      access: {
        name: '',
        email: '',
        password: '',
        password_confirmation: ''
      },

      address: {
        cep: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        states: {
          options: [],
          selected: null
        },
        cities: {
          options: [],
          filteredOptions: [],
          selected: null
        }
      },

      syndicates: {
        telephone: '',
        options: [],
        filteredOptions: [],
        selected: []
      },

      columns: ['id', 'name', 'email', 'action'],
      options: {
        sortable: true,
        filterable: false,
        headings: {
          name: 'Nome',
          email: 'E-mail',
          action: 'Remover'
        },
        hiddenColumns: ['id'],
        sortIcon: {
          is: 'glyphicon-sort',
          base: 'glyphicon',
          up: 'glyphicon-chevron-up',
          down: 'glyphicon-chevron-down'
        },
        texts: {
          count: '',
          noResults: 'Preencha os campos acima para cadastrar um acesso.'
        },
        pagination: {
          chunk: 3
        }
      }
    };
  },

  validations: {
    associate: {
      name: {
        required,
        maxLength: maxLength(100)
      },
      document: { required },
      types: {
        selected: { required }
      },
      entities: {
        selected: { required }
      }
    },

    access: {
      name: {
        required,
        maxLength: maxLength(100)
      },
      email: {
        required,
        email,
        maxLength: maxLength(100)
      },
      password: {
        required,
        minLength: minLength(8)
      },
      password_confirmation: {
        required,
        minLength: minLength(8),
        sameAsPassword: sameAs('password')
      }
    },

    address: {
      cep: { required },
      address: { required },
      number: { required },
      neighborhood: { required },
      states: {
        selected: { required }
      },
      cities: {
        selected: { required }
      }
    },
    syndicates: {
      selected: { required }
    }
  },

  computed: {
    documentMask() {
      return this.isCpf ? '###.###.###-##' : '##.###.###/####-##';
    }
  },

  methods: {
    toggleDocument(entity) {
      this.isCpf = entity.value === 1;
    },

    checkEmailEquals(email) {
      let emails = [];
      if (this.profiles.length >= 1)
        this.profiles.forEach(profile => emails.push(profile.email));

      return emails.includes(email);
    },

    async checkEmailExist(email) {
      const response = await axios.post(
        '/api/cadastrar-empresa/search-in-users',
        {
          email: email
        }
      );

      return response.data;
    },

    async addProfile() {
      let EmailExist = await this.checkEmailExist(this.access.email);

      if (EmailExist) {
        this.$toast.warning('Este e-mail já consta em nossa base de cadastro.');
        return;
      }

      let isEmailEqual = this.checkEmailEquals(this.access.email);

      if (this.profiles.length < 3) {
        if (isEmailEqual) {
          this.$toast.warning('E-mail deve ser diferente dos já cadastrados!');
        } else if (!this.$v.access.$invalid) {
          this.profiles.push(this.access);

          this.access = {
            name: '',
            email: '',
            password: '',
            password_confirmation: ''
          };
        }
      } else {
        this.$toast.error('Não é possivel cadastrar mais de 3 acessos!');
      }
    },

    removeProfile(index) {
      this.profiles.splice(--index, 1);
    },

    grant_revokeAcessAt() {
      this.associate.grant_access_at = moment().format('DD/MM/YYYY');
      this.associate.revoke_access_at = moment()
        .add(60, 'days')
        .format('DD/MM/YYYY');
    },
    closeModal() {
      this.$modal.hide('modal');
      this.redirect = true;
    },
    onComplete() {
      if (this.$v.associate.$invalid) {
        this.$refs.form.changeTab(1, 0);
        this.$v.associate.name.$touch();
        this.$v.associate.document.$touch();
        this.$v.associate.types.selected.$touch();
        this.$v.associate.entities.selected.$touch();

        this.$v.access.name.$touch();
        this.$v.access.email.$touch();
        this.$v.access.password.$touch();
        this.$v.access.password_confirmation.$touch();
      } else if (this.$v.syndicates.$invalid) {
        this.$refs.form.changeTab(1, 0);
        this.$v.syndicates.selected.$touch();
      } else if (this.$v.address.$invalid) {
        this.$refs.form.changeTab(1, 1);

        this.$v.address.cep.$touch();
        this.$v.address.address.$touch();
        this.$v.address.number.$touch();
        this.$v.address.neighborhood.$touch();
        this.$v.address.cities.selected.$touch();
        this.$v.address.states.selected.$touch();
      } else if (this.profiles.length == 0) {
        this.$toast.warning(
          'Adicione pelo menos um acesso para finalizar o cadastro!'
        );
      } else {
        const associateAttributes = {
          syndicates: this.syndicates.selected.map(syndicate => syndicate.id),
          type: this.associate.types.selected.value,
          entity: this.associate.entities.selected.value,
          name: this.associate.name,
          document: this.associate.document,
          telephone: this.associate.telephone,
          cellphone: this.associate.cellphone,
          status: this.associate.status,
          grant_access_at: this.associate.grant_access_at,
          revoke_access_at: this.associate.revoke_access_at
        };

        const addressAttributes = {
          city_id: this.address.cities.selected.id,
          cep: this.address.cep,
          address: this.address.address,
          number: this.address.number,
          complement: this.address.complement,
          neighborhood: this.address.neighborhood
        };

        const userAttributes = this.profiles;

        axios
          .post('/api/associados', associateAttributes)
          .then(({ data }) => {
            this.$toast.success(data.data.message);

            this.syndicates.telephone = data.data.data.syndicates[0].telephone;

            axios
              .post(
                `/api/associados/${data.data.data.id}/endereco`,
                addressAttributes
              )
              .then(({ data }) => {
                this.$toast.success(data.data.message);
              })
              .catch(({ response }) => {
                Object.values(response.data.errors).forEach(error => {
                  this.$toast.error(...error);
                });
              });

            axios
              .post(
                `/api/associados/${data.data.data.id}/profiles`,
                userAttributes
              )
              .then(({ data }) => {
                this.$toast.success(data.data.message);

                this.$modal.show('modal');
              })
              .catch(({ response }) => {
                Object.values(response.data.errors).forEach(error => {
                  this.$toast.error(...error);
                });
              });
          })
          .catch(({ response }) => {
            Object.values(response.data.errors).forEach(error => {
              this.$toast.error(...error);
            });
          });
      }
    },

    goBack() {
      this.$router.go(-1);
    },

    async fetchStates() {
      await axios.get('/api/estados').then(({ data }) => {
        data.data.forEach(state =>
          this.address.states.options.push({
            id: state.id,
            name: state.name
          })
        );
      });
    },

    addSyndicate(syndicate) {
      const tag = {
        name: syndicate.name,
        id: syndicate.id
      };
      this.options.push(tag);
      this.value.push(tag);
    },

    fetchSyndicates() {
      axios.get('/api/cadastrar-empresa/sindicatos').then(({ data }) => {
        data.data.forEach(syndicate => {
          this.syndicates.options.push({
            name: syndicate.name,
            id: syndicate.id
          });
        });
      });
    },

    async asyncLocalStorage() {
      const data = JSON.parse(localStorage.getItem('createAccess'));
      this.associate.document = data.document;
      this.associate.entities.selected = data.entity;

      this.toggleDocument(data.entity);
    },

    removeDiacritics(text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },

    normalizedContains(needle, haystack) {
      const regExp = new RegExp(this.removeDiacritics(needle), 'gi');
      return regExp.test(this.removeDiacritics(haystack));
    },

    syndicatesSearchChange(search) {
      this.syndicates.filteredOptions = search
        ? this.syndicates.options.filter(option =>
          this.normalizedContains(search, option.name)
        )
        : this.syndicates.options;
    },

    citiesSearchChange(search) {
      this.address.cities.filteredOptions = search
        ? this.address.cities.options.filter(option =>
          this.normalizedContains(search, option.name)
        )
        : this.address.cities.options;
    },

    async fetchCities(state) {
      this.address.cities.options = [];
      this.address.cities.selected = null;

      await axios.get(`/api/estados/${state.id}/cidades`).then(({ data }) => {
        data.data.forEach(city =>
          this.address.cities.options.push({
            id: city.id,
            name: city.name
          })
        );
      });
    }
  },

  created() {
    this.grant_revokeAcessAt();
    this.fetchStates();
    this.fetchSyndicates();
    this.asyncLocalStorage();
  },
  watch: {
    redirect() {
      if (this.redirect) this.$router.push({ name: 'login' });
    }
  }
};
</script>

<style scoped>
.error {
  color: red;
}
</style>
